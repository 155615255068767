import { isValid, validate } from './utils/validate';
import { isEnvProduction } from './utils/envUtils';
import request from './utils/request';
import trimData from './utils/trimData';
import logger from './services/logger';

import { DATA_KEY } from './consts';

const main = async () => {
  if (typeof window === 'undefined' || !window[DATA_KEY]) {
    logger.debug(`No ${DATA_KEY} object available under window.`);

    return;
  }

  const payload = window[DATA_KEY];

  if (isValid(payload)) {
    if (await request(trimData(payload))) {
      logger.debug('Payload successfully sent');
    } else {
      logger.error('Cannot send payload');
    }
  } else if (!isEnvProduction()) {
    logger.error('Invalid data:');
    validate(payload).forEach((error) => logger.error(error.message));
  }
};

main();
