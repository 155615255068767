// eslint-disable-next-line no-restricted-imports
import DataMedIQ from '../schema/DataMedIQ';

const validateLeaf = ({ data, propName, typeDef }) => {
  const { required, type } = typeDef;
  const value = data[propName];

  if (required && typeof value === 'undefined') {
    return new Error(`Prop ${propName} is required`);
  }

  if (typeof value === 'undefined') {
    return true;
  }

  if (type === Date) {
    return new Date(value).toString() === 'Invalid Date'
      ? new Error(`Prop ${propName}: Invalid datetime string (${value})`)
      : true;
  }

  if (value.constructor !== type) {
    return new Error(`Prop ${propName}: Type mismatched for value: ${value}`);
  }

  return true;
};

export const validate = (data, schema = DataMedIQ) => Object.entries(schema)
  .map(([propName, typeDef]) => {
    const value = data[propName];

    if (Array.isArray(typeDef)) {
      if (!Array.isArray(value)) {
        return new Error(`${propName} should be an array`);
      }

      return value.map((item) => validate(item, typeDef[0]));
    }

    if (typeof value === 'object') {
      return validate(value, typeDef);
    }

    return validateLeaf({ data, propName, typeDef });
  })
  .flat(Infinity)
  .filter((result) => result instanceof Error);

export const isValid = (data) => {
  const result = validate(data);

  return Array.isArray(result) && result.length === 0;
};
