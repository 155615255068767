const DataMedIQ = {
  supplierId: {
    type: String,
    required: true,
  },
  authToken: {
    type: String,
    required: true,
  },
  orderId: {
    type: String,
    required: true,
  },
  datetime: {
    required: true,
    type: Date,
  },
  orderCurrency: {
    type: String,
    required: true,
  },
  customer: {
    postalCode: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
  },
  cart: [
    {
      pzn: {
        type: String,
        required: true,
      },
      quantity: {
        type: Number,
        required: true,
      },
      amountUnityGross: {
        type: Number,
        required: true,
      },
      amountVatPercentage: {
        type: Number,
        required: true,
      },
    },
  ],
};

export default DataMedIQ;
