import axios from 'axios';
/* eslint-disable no-restricted-imports */
import { STATUS_SUCCESS } from '../consts';
import logger from '../services/logger';

import { isEnvProduction } from './envUtils';

export const getAuthToken = (data) => {
  const { authToken } = data;

  return authToken;
};

const decoratePostRequest = (data) => ({
  method: 'POST',
  url: process.env.CLIENT_APP_API_URL,
  data,
  headers: {
    Authorization: getAuthToken(data),
  },
});

/**
 *
 * @param {object} data
 * @returns {Promise<boolean>}
 */
const request = async (data) => {
  try {
    const response = await axios(decoratePostRequest(data));

    const { status } = response;

    if (STATUS_SUCCESS.includes(status)) {
      return true;
    }

    if (!isEnvProduction()) {
      logger.error(JSON.stringify(response, null, '  '));
    }
  } catch (error) {
    logger.error(error);
  }

  return false;
};

export default request;
